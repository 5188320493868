/**
 * Import all polyfills.
 */
import 'url-search-params-polyfill';
import 'custom-event-polyfill';

/**
 * Import all required libraries.
 */
import Environment from '@flashpointbv/solar-ui-environment';
import Vue from 'vue';
import Vuex from 'vuex';
import * as VueGoogleMaps from 'gmap-vue';
import ServiceContainer from '@flashpointbv/solar-service-container';
import VueI18n from 'vue-i18n';
import VueLazyload from 'vue-lazyload';
import {UICoreSticky, UICoreOffCanvas, UICoreBlazeSlider, UICoreFormValidator} from '@flashpointbv/solar-ui-core';
import {SolarDataLayer} from '@flashpointbv/solar-datalayer/dist';
import 'lazysizes';

new UICoreSticky().sticky();
new UICoreOffCanvas().offCanvas(true);
new UICoreBlazeSlider();
new UICoreFormValidator();

Vue.use(VueI18n);
Vue.use(VueLazyload);
Vue.use(Vuex);

Vue.use(VueGoogleMaps, {
    load: {
        key: window.Config['google_api_key'],
        libraries: 'places',
        region: window.Locale.substring(0, 2),
        language: window.Locale.substring(0, 2).toLowerCase()
    }
});

SolarDataLayer.init();

/**
 * Set important environment variables
 */
Environment().set('locale', window.Locale);
Environment().set('locale_short', window.Locale.substring(0, 2).toLowerCase());
for (let key in window.Config) Environment().set(key, window.Config[key]);

/*
* Create store instance and register the store modules
 */
import {
    CatalogCategory,
    CatalogProduct,
    CatalogBundleProduct,
    CatalogGroupedProduct,
    CatalogConfigurableProduct
} from '@flashpointbv/solar-ui-catalog/dist';

import CatalogProductConfigurator from '../js/stores/modules/catalog/product/configurator';

require('../js/components/checkout/component-registration');

import {
    CheckoutGlobal,
    CheckoutCart,
    CheckoutPayment,
    CheckoutTotals,
    CheckoutAddress,
    CheckoutShipping,
    CheckoutQuote
} from '../js/stores/checkout';

const store = new Vuex.Store({
    strict: false,
});

store.registerModule('CatalogCategory', CatalogCategory);

store.registerModule('CatalogProduct', CatalogProduct);
store.registerModule('CatalogBundleProduct', CatalogBundleProduct);
store.registerModule('CatalogGroupedProduct', CatalogGroupedProduct);
store.registerModule('CatalogConfigurableProduct', CatalogConfigurableProduct);

store.registerModule('CatalogProductConfigurator', CatalogProductConfigurator);

store.registerModule('CheckoutQuote', CheckoutQuote);
store.registerModule('CheckoutGlobal', CheckoutGlobal);
store.registerModule('CheckoutTotals', CheckoutTotals);
store.registerModule('CheckoutPayment', CheckoutPayment);
store.registerModule('CheckoutAddress', CheckoutAddress);
store.registerModule('CheckoutShipping', CheckoutShipping);
store.registerModule('CheckoutCart', CheckoutCart);

/**
 * Require application scripts.
 */
require('./prototypes');
require('./core');

/**
 * Import all required styles.
 */
import '../scss/app.scss';

/**
 * Register Vue components.
 */
ServiceContainer().addComponents([
    {
        key: 'header-cart',
        concrete: require('./components/HeaderCart').default
    },
    {
        key: 'search-form',
        concrete: require('./components/search/SearchForm.vue').default,
    },
    {
        key: 'page-builder-store-locator',
        concrete: () => import('./components/page-builder/StoreLocator.vue')
    },
    {
        key: 'catalog-category-view',
        concrete: () => import('./views/catalog/CatalogCategoryView.vue')
    },
    {
        key: 'catalog-search-view',
        concrete: () => import('./views/catalog/CatalogSearchView.vue')
    },
    {
        key: 'catalog-product-list',
        concrete: () => import('./components/catalog/ProductList.vue')
    },
    {
        key: 'catalog-product-list-item',
        concrete: () => import('./components/catalog/ProductListItem.vue')
    },
    {
        key: 'catalog-product-add-to-cart',
        concrete: () => import('./components/catalog/product/ProductAddToCart.vue'),
    },
    {
        key: 'catalog-product-configurator-save-button',
        concrete: () => import('./components/catalog/product/ConfiguratorSaveButton.vue'),
    },
    {
        key: 'catalog-product-list-toolbar',
        concrete: () => import('./components/catalog/toolbar/ProductListToolbar.vue')
    },
    {
        key: 'catalog-product-list-toolbar-paginator',
        concrete: () => import('./components/catalog/toolbar/ProductListToolbarPaginator.vue')
    },
    {
        key: 'catalog-product-list-toolbar-sort-select',
        concrete: () => import('./components/catalog/toolbar/ProductListToolbarSortSelect.vue')
    },
    {
        key: 'catalog-product-list-toolbar-size-select',
        concrete: () => import('./components/catalog/toolbar/ProductListToolbarSizeSelect.vue')
    },
    {
        key: 'catalog-product-list-toolbar-active-filters',
        concrete: () => import('./components/catalog/toolbar/ProductListToolbarActiveFilters.vue')
    },
    {
        key: 'catalog-product-filter-list',
        concrete: () => import('./components/catalog/ProductFilterList.vue'),
    },
    {
        key: 'catalog-product-list-attribute-filter',
        concrete: () => import('./components/catalog/filter/AttributeFilter.vue'),
    },
    {
        key: 'catalog-product-list-swatch-filter',
        concrete: () => import('./components/catalog/filter/SwatchFilter.vue'),
    },
    {
        key: 'catalog-product-list-price-filter',
        concrete: () => import('./components/catalog/filter/PriceFilter.vue'),
    },
    {
        key: 'customer-address-autocomplete',
        concrete: () => import('../../../../assets/vendor/customer/web/js/components/customer/AddressAutocomplete.vue'),
    },
    {
        key: 'physical-store-store-locator',
        concrete: () => import('./components/physical-store/StoreLocator.vue'),
    },
    {
        key: 'catalog-product-configurator',
        concrete: () => import('./components/catalog/product/configurator/ProductConfigurator.vue'),
    },
    {
        key: 'catalog-product-configurator-price',
        concrete: () => import('./components/catalog/product/configurator/ProductConfiguratorPrice.vue'),
    },
    {
        key: 'catalog-product-configurator-media',
        concrete: () => import('./components/catalog/product/ConfiguratorMedia.vue'),
    },
    {key: 'catalog-product-configuration', concrete: () => import('../../default/js/components/catalog/product/ProductConfiguration.vue')},
    {key: 'catalog-product-price', concrete: () => import('../../default/js/components/catalog/product/ProductPrice.vue')},
    {key: 'catalog-product-simple', concrete: () => import('../../default/js/components/catalog/product/simple/ProductTypeSimple.vue')},
    {key: 'catalog-product-configurable', concrete: () => import('../../default/js/components/catalog/product/configurable/ProductTypeConfigurable.vue')},
    {key: 'catalog-product-configurable-select', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSelect.vue')},
    {key: 'catalog-product-configurable-swatch', concrete: () => import('../../default/js/components/catalog/product/configurable/type/OptionSwatch.vue')},
    {
        key: 'sales-force-form',
        concrete: () => import('./components/sales-force/Form.vue'),
    },
    {
      key: 'tooltip',
      concrete: () => import('./components/Tooltip.vue'),
    }
]);

import nl_NL from './i18n/nl_NL';
import en_GB from './i18n/en_GB';
import de_DE from './i18n/de_DE';
import fr_FR from './i18n/fr_FR';

import {localize} from 'vee-validate';
import vee_en_GB from 'vee-validate/dist/locale/en.json';
import vee_nl_NL from 'vee-validate/dist/locale/nl.json';
import vee_de_DE from 'vee-validate/dist/locale/de.json';
import vee_fr_FR from 'vee-validate/dist/locale/fr.json';

localize({vee_en_GB, vee_nl_NL, vee_de_DE, vee_fr_FR});
localize(`vee_${window.Locale === 'nl_BE' ? 'nl_NL' : window.Locale}`);

const messages = {
    nl_NL,
    en_GB,
    fr_FR,
    de_DE,
};

const i18n = new VueI18n({
    locale: window.Locale,
    fallbackLocale: 'nl_NL',
    formatFallbackMessages: true,
    silentTranslationWarn: true,
    messages
});

new Vue({
    store,
    i18n,
    components: ServiceContainer().getComponents(),
    data: {
        csrfToken: window.csrfToken
    },
    async created() {
        this.$eventBus.$on('item-rendered', () => {
            const catalogCategoryProductPlaceholders = document.querySelector('.category__placeholder');
            if (catalogCategoryProductPlaceholders) {
                (catalogCategoryProductPlaceholders as HTMLElement).style.display = 'none';
            }
        });
    }
}).$mount('#solar-app');
