export default {
    "back": "Zur\u00fcck",
    "Sort by": "Sortieren nach",
    "From": "Von",
    "Add to cart": "In den Warenkorb legen",
    "Popularity": "Beliebtheit",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "Price (Ascending)": "Preis (aufsteigend)",
    "Price (Descending)": "Preis (absteigend)",
    "Clear all": "Alles l\u00f6schen",
    "Apply": "Anwenden",
    "Failed to add to cart": "In den Warenkorb legen fehlgeschlagen",
    "Adding to cart": "Wird zum Warenkorb hinzugef\u00fcgt",
    "Added to cart": "Zum Warenkorb hinzugef\u00fcgt",
    "Search": "Suchen",
    "Product information": "Produktinformation",
    "Specifications": "Spezifikationen",
    "User manuals": "Benutzerhandb\u00fccher",
    "Cart": "Warenkorb",
    "Subtotal": "Zwischensumme",
    "Shipping & Handling": "Versand und Bearbeitung",
    "Grand Total": "Gesamtsumme",
    "Free": "Kostenlos",
    "Secure connection": "Sichere Verbindung",
    "To checkout": "Zur Kasse gehen",
    "Postal code": "Postleitzahl",
    "House number": "Hausnummer",
    "House no.": "Hausnr.",
    "Addition": "Zusatz",
    "Add.": "Zusatz",
    "Click here to automatically retrieve your address": "Hier klicken, um Ihre Adresse automatisch abzurufen",
    "We have found the following address": "Wir haben folgende Adresse gefunden",
    "Invalid address. Are you sure it is correct?": "Ung\u00fcltige Adresse. Sind Sie sicher, dass sie korrekt ist?",
    "Click here to enter the address manually.": "Hier klicken, um die Adresse manuell einzugeben",
    "Street": "Stra\u00dfe",
    "City": "Stadt",
    "First name": "Vorname",
    "Last name": "Nachname",
    "Country": "Land",
    "Telephone": "Telefon",
    "Add new address": "Neue Adresse hinzuf\u00fcgen",
    "Select your address": "Ihre Adresse ausw\u00e4hlen",
    "Already have an account with us? Log in {here}": "Haben Sie bereits ein Konto bei uns? Melden Sie sich {hier} an",
    "here": "hier",
    "Don't want to log in? Click {here}": "M\u00f6chten Sie sich nicht anmelden? Klicken Sie {hier}",
    "Email address": "E-Mail-Adresse",
    "You have an account. Would you like to {action}?": "Sie haben bereits ein Konto. M\u00f6chten Sie {action}?",
    "Login": "Anmelden",
    "Password": "Passwort",
    "Log in": "Anmelden",
    "Forgot password": "Passwort vergessen",
    "Shipping address": "Lieferadresse",
    "Sign up for our newsletter": "Melden Sie sich f\u00fcr unseren Newsletter an",
    "Billing address is different from the address above": "Rechnungsadresse unterscheidet sich von der oben genannten Adresse",
    "Billing address": "Rechnungsadresse",
    "Payment methods": "Zahlungsmethoden",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Etwas ist schiefgegangen, die Zahlung konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut oder w\u00e4hlen Sie eine andere Zahlungsmethode.",
    "Please wait, loading payment methods": "Bitte warten, Zahlungsmethoden werden geladen",
    "Shipping methods": "Versandmethoden",
    "Please wait, loading shipping methods": "Bitte warten, Versandmethoden werden geladen",
    "Overview of your order": "\u00dcbersicht Ihrer Bestellung",
    "Change": "\u00c4ndern",
    "Your order will be delivered on": "Ihre Bestellung wird am",
    "Details": "Details",
    "Shipping": "Versand",
    "Payment": "Zahlung",
    "Back to cart": "Zur\u00fcck zum Warenkorb",
    "To shipping": "Zum Versand",
    "To payment": "Zur Zahlung",
    "Complete order": "Bestellung abschlie\u00dfen",
    "Unable to place order, please try again later.": "Bestellung konnte nicht aufgegeben werden, bitte versuchen Sie es sp\u00e4ter erneut.",
    "Loading address...": "Adresse wird geladen...",
    "Without extra fees": "Ohne zus\u00e4tzliche Geb\u00fchren",
    "Thank you for your purchase!": "Vielen Dank f\u00fcr Ihren Kauf!",
    "Your order has been successfully placed.": "Ihre Bestellung wurde erfolgreich aufgegeben.",
    "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Ihre Bestellnummer lautet: #{order}Id. Sie erhalten innerhalb von 15 Minuten eine E-Mail zur Best\u00e4tigung Ihrer Bestellung mit allen Details. \u00dcberpr\u00fcfen Sie auch Ihren Spam-Ordner, falls Sie nach 15 Minuten keine Best\u00e4tigungs-E-Mail erhalten haben. Bei Fragen zum Status Ihrer Bestellung empfehlen wir, sich an den Kundenservice zu wenden.",
    "Your ordered products": "Ihre bestellten Produkte",
    "Zip code or municipality": "Suche nach Standort",
    "Plan an appointment": "Termin planen",
    "Choose your country": "Ihr Land ausw\u00e4hlen",
    "No stores were found near the specified address.": "Es wurden keine Gesch\u00e4fte in der N\u00e4he der angegebenen Adresse gefunden.",
    "Something went wrong while searching for stores. Try again later.": "Bei der Suche nach Gesch\u00e4ften ist ein Fehler aufgetreten. Versuchen Sie es sp\u00e4ter erneut.",
    "Search results for &ldquo;{term}&rdquo;": "Suchergebnisse f\u00fcr &ldquo;{term}&rdquo;",
    "Categories": "Kategorien",
    "Price": "Preis",
    "Shopping cart": "Einkaufswagen",
    "You have no product(s) in your shopping cart.": "Sie haben keine Produkt(e) in Ihrem Einkaufswagen.",
    "Click here to continue shopping.": "Klicken Sie hier, um mit dem Einkauf fortzufahren.",
    "Recently viewed products": "Zuletzt angesehene Produkte",
    "No products were found for &ldquo;{term}&rdquo;.": "Es wurden keine Produkte f\u00fcr &ldquo;{term}&rdquo; gefunden.",
    "Stores": "Gesch\u00e4fte",
    "View store": "Gesch\u00e4ft anzeigen",
    "Use my location": "Meinen Standort verwenden",
    "Show all articles": "Alle Artikel anzeigen",
    "Your total": "Ihr Gesamtbetrag",
    "incl. TAX": "inkl. MwSt.",
    "Save this configuration": "Diese Konfiguration speichern",
    "Order now": "Jetzt bestellen",
    "Yes": "Ja",
    "No": "Nein",
    "Copy": "Kopieren",
    "Copied": "Kopiert",
    "View details": "Details anzeigen",
    "Show more": "Mehr anzeigen",
    "Show less": "Weniger anzeigen",
    "The account login is incorrect or your account is temporarily disabled. Please wait and try again later.": "Die Anmeldung ist fehlerhaft oder Ihr Konto ist vor\u00fcbergehend deaktiviert. Bitte warten Sie und versuchen Sie es sp\u00e4ter erneut.",
    "Blog categories": "Blog-Kategorien",
    "Placed on": "Platziert am",
    "Share this post": "Diesen Beitrag teilen",
    "My profile": "Mein Profil",
    "What are you looking for": "Was suchen Sie",
    "Our advice": "Unser Rat",
    "Be inspired": "Lassen Sie sich inspirieren",
    "Follow us": "Folgen Sie uns",
    "My account": "Mein Konto",
    "Confirm password": "Passwort best\u00e4tigen",
    "Forgot password?": "Passwort vergessen?",
    "New customers": "Neue Kunden",
    "Telefoonnummer is not valid": "Telefonnummer ist ung\u00fcltig",
    "E-mail adres is not valid": "E-Mail-Adresse ist ung\u00fcltig",
    "Voornaam is not valid": "Vorname ist ung\u00fcltig",
    "Achternaam is not valid": "Nachname ist ung\u00fcltig",
    "Postcode is not valid": "Postleitzahl ist ung\u00fcltig",
    "Huisnummer is not valid": "Hausnummer ist ung\u00fcltig",
    "Create account": "Konto erstellen",
    "New customer": "Neuer Kunde",
    "Enter a correct value": "Geben Sie einen korrekten Wert ein",
    "You are now signed out": "Sie sind jetzt abgemeldet",
    "You are now logged out and will return to our home page within 5 seconds.": "Sie sind jetzt abgemeldet und werden innerhalb von 5 Sekunden zur Startseite zur\u00fcckkehren.",
    "Male": "M\u00e4nnlich",
    "Female": "Weiblich",
    "Subscribe to our newsletter": "Abonnieren Sie unseren Newsletter",
    "Welcome {name}": "Willkommen {name}",
    "Hi {name}, here you can find the state of your order, payments and personal details.": "Hallo {name}, hier finden Sie den Status Ihrer Bestellungen, Zahlungen und pers\u00f6nlichen Details.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Diese pers\u00f6nliche Seite bietet Ihnen einen \u00dcberblick \u00fcber die neuesten Aktivit\u00e4ten. Sie k\u00f6nnen unter anderem Bestellungen anzeigen und Kontoinformationen aktualisieren. Klicken Sie unten auf einen Link, um loszulegen!",
    "Recent orders": "Letzte Bestellungen",
    "You have not placed any orders yet": "Sie haben noch keine Bestellungen aufgegeben",
    "Order #": "Bestellung Nr.",
    "Date": "Datum",
    "Ship to": "Versand an",
    "Total": "Gesamt",
    "View order": "Bestellung anzeigen",
    "Personal settings": "Pers\u00f6nliche Einstellungen",
    "Contact details": "Kontaktdaten",
    "Edit": "Bearbeiten",
    "Change password": "Passwort \u00e4ndern",
    "Newsletter": "Newsletter",
    "You are subscribed to our newsletter": "Sie sind f\u00fcr unseren Newsletter angemeldet",
    "Your account has been created successfully.": "Ihr Konto wurde erfolgreich erstellt.",
    "subscribe": "abonnieren",
    "Addresses": "Adressen",
    "Default shipping address": "Standard-Versandadresse",
    "Default billing address": "Standard-Rechnungsadresse",
    "Your default shipping address": "Ihre Standard-Versandadresse",
    "No default shipping address available": "Keine Standard-Versandadresse verf\u00fcgbar",
    "Your default billing address": "Ihre Standard-Rechnungsadresse",
    "No default billing address available": "Keine Standard-Rechnungsadresse verf\u00fcgbar",
    "Edit personal settings": "Pers\u00f6nliche Einstellungen bearbeiten",
    "Current password": "Aktuelles Passwort",
    "New password": "Neues Passwort",
    "Confirm new password": "Neues Passwort best\u00e4tigen",
    "Save": "Speichern",
    "Company": "Firma",
    "Phone number": "Telefonnummer",
    "Address": "Adresse",
    "Postal code + house number": "Postleitzahl + Hausnummer",
    "Select a country": "Land ausw\u00e4hlen",
    "Save address": "Adresse speichern",
    "Edit address": "Adresse bearbeiten",
    "Extra Addresses": "Zus\u00e4tzliche Adressen",
    "No addresses available": "Keine Adressen verf\u00fcgbar",
    "Remove": "Entfernen",
    "Account overview": "Konto\u00fcbersicht",
    "My orders": "Meine Bestellungen",
    "My reviews": "Meine Bewertungen",
    "Newsletters": "Newsletter",
    "Wishlist": "Wunschliste",
    "Logout": "Abmelden",
    "Do you receive our newsletter?": "Erhalten Sie unseren Newsletter?",
    "Order {increment}": "Bestellung {increment}",
    "Shipping method": "Versandart",
    "Payment method": "Zahlungsmethode",
    "Ordered items": "Bestellte Artikel",
    "Item": "Artikel",
    "SKU": "Artikelnummer",
    "Quantity": "Menge",
    "Grand total": "Gesamtsumme",
    "Request password": "Passwort anfordern",
    "Send email": "E-Mail senden",
    "You have not reviewed any products yet": "Sie haben noch keine Produkte bewertet",
    "Successfully created an account, you can log in with your new credentials": "Ihr Konto wurde erfolgreich erstellt, Sie k\u00f6nnen sich jetzt mit Ihren neuen Anmeldedaten anmelden",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Wenn ein Konto mit {email} gefunden wird, erhalten Sie eine E-Mail mit einem Link, um Ihr Passwort zur\u00fcckzusetzen.",
    "Unable to save customer": "Kunde konnte nicht gespeichert werden",
    "Successfully updated customer": "Kunde erfolgreich aktualisiert",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "Die Anmeldung am Konto ist inkorrekt oder Ihr Konto wurde vor\u00fcbergehend deaktiviert. Bitte warten Sie und versuchen Sie es sp\u00e4ter erneut.",
    "Successfully saved address": "Adresse erfolgreich gespeichert",
    "Address not found": "Adresse nicht gefunden",
    "Unable to update newsletter subscription": "Aktualisierung des Newsletter-Abonnements nicht m\u00f6glich",
    "Successfully updated newsletter subscription": "Newsletter-Abonnement erfolgreich aktualisiert",
    "Something went wrong while recovering your password": "Es ist ein Fehler aufgetreten, w\u00e4hrend Ihr Passwort wiederhergestellt wurde",
    "Your password has been updated": "Ihr Passwort wurde aktualisiert",
    "Something went wrong, please try again": "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut",
    "Unable to remove address": "Adresse konnte nicht entfernt werden",
    "Successfully removed address": "Adresse erfolgreich entfernt",
    "Order not found": "Bestellung nicht gefunden",
    "Review not found": "Bewertung nicht gefunden",
    "successfully logged out": "Erfolgreich abgemeldet",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Die Kontoanmeldung war inkorrekt oder Ihr Konto ist vor\u00fcbergehend deaktiviert. Bitte warten Sie und versuchen Sie es sp\u00e4ter erneut.",
    "If an account with the given email address exists you will receive a password reset email.": "Wenn ein Konto mit der angegebenen E-Mail-Adresse existiert, erhalten Sie eine E-Mail zur Zur\u00fccksetzung Ihres Passworts.",
    "Reset password": "Passwort zur\u00fccksetzen",
    "validation.required": "Dies ist ein Pflichtfeld",
    "validation.email": "Geben Sie eine g\u00fcltige E-Mail-Adresse ein",
    "Your last order": "Ihre letzte Bestellung",
    "View all orders": "Alle Bestellungen anzeigen",
    "Details & Preferences": "Details und Einstellungen",
    "Default addresses": "Standardadressen",
    "View all addresses": "Alle Adressen anzeigen",
    "Pending": "Ausstehend",
    "Processing": "In Bearbeitung",
    "Pending Payment": "Ausstehende Zahlung",
    "Complete": "Abgeschlossen",
    "Cancelled": "Abgebrochen",
    "Closed": "Geschlossen",
    "On Hold": "In Warteposition",
    "Payment Review": "Zahlungs\u00fcberpr\u00fcfung",
    "Fraud": "Betrug",
    "Ordered on": "Bestellt am",
    "Download invoice": "Rechnung herunterladen",
    "Order details": "Bestelldetails",
    "Order number": "Bestellnummer",
    "subtotal": "Zwischensumme",
    "total_shipping": "Versandkosten",
    "grand_total": "Gesamtsumme",
    "Address and payment information": "Adress- und Zahlungsinformationen",
    "Back to my orders": "Zur\u00fcck zu meinen Bestellungen",
    "Support level": "Support-Level",
    "No products were found in this category.": "Es wurden keine Produkte in dieser Kategorie gefunden.",
    "Choose your {option}": "W\u00e4hlen Sie Ihre {option}",
    "This product is currently sold out": "Dieses Produkt ist derzeit ausverkauft",
    "Choose an option": "Eine Option ausw\u00e4hlen",
    "Product has been added to your shopping cart": "Produkt wurde Ihrem Warenkorb hinzugef\u00fcgt",
    "Saving configuration...": "Konfiguration wird gespeichert...",
    "Configuration code ready!": "Konfigurationscode bereit!",
    "The configuration could not be saved, please try again later.": "Die Konfiguration konnte nicht gespeichert werden, bitte versuchen Sie es sp\u00e4ter erneut.",
    "Your unique code!": "Ihr eindeutiger Code!",
    "Copy code": "Code kopieren",
    "E-mail code": "Code per E-Mail senden",
    "Code copied!": "Code kopiert!",
    "Code sent!": "Code gesendet!",
    "Something went wrong with sending the code, please try again.": "Beim Senden des Codes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
    "This product is available in the following options": "Dieses Produkt ist in den folgenden Optionen verf\u00fcgbar",
    "Show address": "Adresse anzeigen",
    "We couldn't retrieve your location, please try again or use the search function.": "Wir konnten Ihren Standort nicht abrufen, bitte versuchen Sie es erneut oder verwenden Sie die Suchfunktion.",
    "Find your Ergosleep\u00ae store": "Finden Sie Ihr Ergosleep\u00ae-Gesch\u00e4ft",
    "Send": "Senden",
    "Configure this bed": "Erstellen Sie Ihr Traumbett",
    "Your unique bed": "Ihr einzigartiges Bett",
    "Newest": "Neueste",
    "View configuration": "Konfiguration anzeigen",
    "Productverrijking": "Produktspezifikationen",
    "Read more": "Mehr lesen",
    "Find a store near you.": "Finden Sie ein Gesch\u00e4ft in Ihrer N\u00e4he.",
    "Filter by country": "Nach Land filtern",
    "All countries": "Alle L\u00e4nder",
    "Filter": "Filtern",
    "Opening hours": "\u00d6ffnungszeiten",
    "Regular opening hours": "Regul\u00e4re \u00d6ffnungszeiten",
    "Monday": "Montag",
    "Tuesday": "Dienstag",
    "Wednesday": "Mittwoch",
    "Thursday": "Donnerstag",
    "Friday": "Freitag",
    "Saturday": "Samstag",
    "Sunday": "Sonntag",
    "Open by appointment": "Nach Vereinbarung ge\u00f6ffnet",
    "Address details": "Adressdetails",
    "Special holidays": "Besondere Feiertage",
    "Measure your SLEEP-DNA\u00ae": "Messen Sie Ihre SLEEP-DNA\u00ae",
    "Direction information": "Richtungsinformationen",
    "Accessibility": "Erreichbarkeit",
    "Make an appointment": "Vereinbaren Sie einen Termin",
    "Continue in my current language.": "In meiner aktuellen Sprache fortfahren."
}