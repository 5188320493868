export default {
    "back": "Retour",
    "Sort by": "Trier par",
    "From": "De",
    "Add to cart": "Ajouter au panier",
    "Popularity": "Popularit\u00e9",
    "Name (A-Z)": "Nom (A-Z)",
    "Name (Z-A)": "Nom (Z-A)",
    "Price (Ascending)": "Prix (croissant)",
    "Price (Descending)": "Prix (d\u00e9croissant)",
    "Clear all": "Effacer tout",
    "Apply": "Appliquer",
    "Failed to add to cart": "Impossible d'ajouter au panier",
    "Adding to cart": "Ajout au panier en cours",
    "Added to cart": "Ajout\u00e9 au panier",
    "Search": "Rechercher",
    "Product information": "Informations sur le produit",
    "Specifications": "Sp\u00e9cifications",
    "User manuals": "Manuels d'utilisation",
    "Cart": "Panier",
    "Subtotal": "Sous-total",
    "Shipping & Handling": "Frais de port et de manutention",
    "Grand Total": "Total g\u00e9n\u00e9ral",
    "Free": "Gratuit",
    "Secure connection": "Connexion s\u00e9curis\u00e9e",
    "To checkout": "Passer \u00e0 la caisse",
    "Postal code": "Code postal",
    "House number": "Num\u00e9ro de maison",
    "House no.": "N\u00b0 de maison",
    "Addition": "Addition",
    "Add.": "Add.",
    "Click here to automatically retrieve your address": "Cliquez ici pour r\u00e9cup\u00e9rer automatiquement votre adresse",
    "We have found the following address": "Nous avons trouv\u00e9 l'adresse suivante",
    "Invalid address. Are you sure it is correct?": "Adresse invalide. \u00cates-vous s\u00fbr que c'est correct ?",
    "Click here to enter the address manually.": "Cliquez ici pour entrer l'adresse manuellement.",
    "Street": "Rue",
    "City": "Ville",
    "First name": "Pr\u00e9nom",
    "Last name": "Nom de famille",
    "Country": "Pays",
    "Telephone": "T\u00e9l\u00e9phone",
    "Add new address": "Ajouter une nouvelle adresse",
    "Select your address": "S\u00e9lectionnez votre adresse",
    "Already have an account with us? Log in {here}": "Vous avez d\u00e9j\u00e0 un compte chez nous ? Connectez-vous {here}",
    "here": "ici",
    "Don't want to login? Click {here}": "Vous ne voulez pas vous connecter ? Cliquez {ici}",
    "Email address": "Adresse e-mail",
    "You have an account. Would you like to {action}?": "Vous avez un compte. Souhaitez-vous {action} ?",
    "Login": "Connexion",
    "Password": "Mot de passe",
    "Log in": "Se connecter",
    "Forgot password": "Mot de passe oubli\u00e9",
    "Shipping address": "Adresse de livraison",
    "Sign up for our newsletter": "Inscrivez-vous \u00e0 notre newsletter",
    "Billing address is different from the address above": "L'adresse de facturation est diff\u00e9rente de l'adresse ci-dessus",
    "Billing address": "Adresse de facturation",
    "Payment methods": "Moyens de paiement",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Une erreur s'est produite, le paiement n'a pas pu \u00eatre effectu\u00e9. Veuillez r\u00e9essayer ou choisir un autre moyen de paiement.",
    "Please wait, loading payment methods": "Veuillez patienter, chargement des moyens de paiement en cours",
    "Shipping methods": "M\u00e9thodes d'exp\u00e9dition",
    "Please wait, loading shipping methods": "Veuillez patienter, chargement des m\u00e9thodes d'exp\u00e9dition en cours",
    "Overview of your order": "Aper\u00e7u de votre commande",
    "Change": "Changer",
    "Your order will be delivered on": "Votre commande sera livr\u00e9e le",
    "Details": "D\u00e9tails",
    "Shipping": "Exp\u00e9dition",
    "Payment": "Paiement",
    "Back to cart": "Retour au panier",
    "To shipping": "Vers l'exp\u00e9dition",
    "To payment": "Vers le paiement",
    "Complete order": "Finaliser la commande",
    "Unable to place order, please try again later.": "Impossible de passer la commande, veuillez r\u00e9essayer ult\u00e9rieurement.",
    "Loading address...": "Chargement de l'adresse en cours...",
    "Without extra fees": "Sans frais suppl\u00e9mentaires",
    "Thank you for your purchase!": "Merci pour votre achat !",
    "Your order has been successfully placed.": "Votre commande a \u00e9t\u00e9 pass\u00e9e avec succ\u00e8s.",
    "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Votre num\u00e9ro de commande est : #{order}Id. Vous recevrez un e-mail confirmant votre commande avec tous les d\u00e9tails dans les 15 minutes. Veuillez \u00e9galement v\u00e9rifier votre dossier de spam si vous n'avez pas re\u00e7u d'e-mail de confirmation apr\u00e8s 15 minutes. Si vous avez des questions sur l'\u00e9tat de votre commande, il est pr\u00e9f\u00e9rable de contacter le service client.",
    "Your ordered products": "Vos produits command\u00e9s",
    "Zip code or municipality": "Rechercher par emplacement",
    "Plan an appointment": "Planifier un rendez-vous",
    "Choose your country": "Choisissez votre pays",
    "No stores were found near the specified address.": "Aucun magasin n'a \u00e9t\u00e9 trouv\u00e9 pr\u00e8s de l'adresse sp\u00e9cifi\u00e9e.",
    "Something went wrong while searching for stores. Try again later.": "Une erreur s'est produite lors de la recherche de magasins. R\u00e9essayez plus tard.",
    "Search results for &ldquo;{term}&rdquo;": "R\u00e9sultats de recherche pour &ldquo;{term}&rdquo;",
    "Categories": "Cat\u00e9gories",
    "Price": "Prix",
    "Shopping cart": "Panier d'achat",
    "You have no product(s) in your shopping cart.": "Vous n'avez aucun produit dans votre panier d'achat.",
    "Click here to continue shopping.": "Cliquez ici pour continuer vos achats.",
    "Recently viewed products": "Produits r\u00e9cemment consult\u00e9s",
    "No products were found for &ldquo;{term}&rdquo;.": "Aucun produit n'a \u00e9t\u00e9 trouv\u00e9 pour &ldquo;{term}&rdquo;.",
    "Stores": "Magasins",
    "View store": "Voir le magasin",
    "Use my location": "Utiliser ma position",
    "Show all articles": "Afficher tous les articles",
    "Your total": "Votre total",
    "incl. TAX": "TVA incluse",
    "Save this configuration": "Enregistrer cette configuration",
    "Order now": "Commander maintenant",
    "Yes": "Oui",
    "No": "Non",
    "Copy": "Copier",
    "Copied": "Copi\u00e9",
    "View details": "Voir les d\u00e9tails",
    "Show more": "Afficher plus",
    "Show less": "Afficher moins",
    "The account login is incorrect or your account is temporarily disabled. Please wait and try again later.": "La connexion au compte est incorrecte ou votre compte est temporairement d\u00e9sactiv\u00e9. Veuillez patienter et r\u00e9essayer ult\u00e9rieurement.",
    "Blog categories": "Cat\u00e9gories de blog",
    "Placed on": "Plac\u00e9 le",
    "Share this post": "Partager ce message",
    "My profile": "Mon profil",
    "What are you looking for": "Que recherchez-vous",
    "Our advice": "Notre conseil",
    "Be inspired": "Laissez-vous inspirer",
    "Follow us": "Suivez-nous",
    "My account": "Mon compte",
    "Confirm password": "Confirmer le mot de passe",
    "Forgot password?": "Mot de passe oubli\u00e9 ?",
    "New customers": "Nouveaux clients",
    "Telefoonnummer is not valid": "Num\u00e9ro de t\u00e9l\u00e9phone non valide",
    "E-mail adres is not valid": "Adresse e-mail non valide",
    "Voornaam is not valid": "Pr\u00e9nom non valide",
    "Achternaam is not valid": "Nom de famille non valide",
    "Postcode is not valid": "Code postal non valide",
    "Huisnummer is not valid": "Num\u00e9ro de maison non valide",
    "Create account": "Cr\u00e9er un compte",
    "New customer": "Nouveau client",
    "Enter a correct value": "Veuillez saisir une valeur correcte",
    "You are now signed out": "Vous \u00eates maintenant d\u00e9connect\u00e9",
    "You are now logged out and will return to our home page within 5 seconds.": "Vous \u00eates maintenant d\u00e9connect\u00e9 et vous retournerez sur notre page d'accueil dans 5 secondes.",
    "Male": "Homme",
    "Female": "Femme",
    "Subscribe to our newsletter": "Abonnez-vous \u00e0 notre newsletter",
    "Welcome {name}": "Bienvenue {name}",
    "Hi {name}, here you can find the state of your order, payments and personal details.": "Bonjour {name}, vous pouvez ici trouver l'\u00e9tat de votre commande, les paiements et les d\u00e9tails personnels.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Cette page personnelle vous offre un aper\u00e7u des activit\u00e9s les plus r\u00e9centes. Vous pouvez, entre autres, consulter les commandes et mettre \u00e0 jour les informations de votre compte. Cliquez sur un lien ci-dessous pour commencer !",
    "Recent orders": "Commandes r\u00e9centes",
    "You have not placed any orders yet": "Vous n'avez encore pass\u00e9 aucune commande",
    "Order #": "Commande n\u00b0",
    "Date": "Date",
    "Ship to": "Exp\u00e9dier \u00e0",
    "Total": "Total",
    "View order": "Voir la commande",
    "Personal settings": "Param\u00e8tres personnels",
    "Contact details": "Coordonn\u00e9es",
    "Edit": "Modifier",
    "Change password": "Changer le mot de passe",
    "Newsletter": "Bulletin d'information",
    "You are subscribed to our newsletter": "Vous \u00eates abonn\u00e9 \u00e0 notre bulletin d'information",
    "Your account has been created successfully.": "Votre compte a \u00e9t\u00e9 cr\u00e9\u00e9 avec succ\u00e8s.",
    "subscribe": "s'abonner",
    "Addresses": "Adresses",
    "Default shipping address": "Adresse de livraison par d\u00e9faut",
    "Default billing address": "Adresse de facturation par d\u00e9faut",
    "Your default shipping address": "Votre adresse de livraison par d\u00e9faut",
    "No default shipping address available": "Aucune adresse de livraison par d\u00e9faut disponible",
    "Your default billing address": "Votre adresse de facturation par d\u00e9faut",
    "No default billing address available": "Aucune adresse de facturation par d\u00e9faut disponible",
    "Edit personal settings": "Modifier les param\u00e8tres personnels",
    "Current password": "Mot de passe actuel",
    "New password": "Nouveau mot de passe",
    "Confirm new password": "Confirmer le nouveau mot de passe",
    "Save": "Enregistrer",
    "Company": "Soci\u00e9t\u00e9",
    "Phone number": "Num\u00e9ro de t\u00e9l\u00e9phone",
    "Address": "Adresse",
    "Postal code + house number": "Code postal + num\u00e9ro de maison",
    "Select a country": "S\u00e9lectionnez un pays",
    "Save address": "Enregistrer l'adresse",
    "Edit address": "Modifier l'adresse",
    "Extra Addresses": "Adresses suppl\u00e9mentaires",
    "No addresses available": "Aucune adresse disponible",
    "Remove": "Supprimer",
    "Account overview": "Aper\u00e7u du compte",
    "My orders": "Mes commandes",
    "My reviews": "Mes avis",
    "Newsletters": "Bulletins d'information",
    "Wishlist": "Liste de souhaits",
    "Logout": "D\u00e9connexion",
    "Do you receive our newsletter?": "Recevez-vous notre bulletin d'information ?",
    "Order {increment}": "Commande {increment}",
    "Shipping method": "M\u00e9thode d'exp\u00e9dition",
    "Payment method": "M\u00e9thode de paiement",
    "Ordered items": "Articles command\u00e9s",
    "Item": "Article",
    "SKU": "SKU",
    "Quantity": "Quantit\u00e9",
    "Grand total": "Total",
    "Request password": "Demander un mot de passe",
    "Send email": "Envoyer un e-mail",
    "You have not reviewed any products yet": "Vous n'avez pas encore donn\u00e9 votre avis sur des produits",
    "Successfully created an account, you can log in with your new credentials": "Compte cr\u00e9\u00e9 avec succ\u00e8s, vous pouvez vous connecter avec vos nouvelles informations d'identification",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Si un compte est trouv\u00e9 avec l'adresse {email}, vous recevrez un e-mail avec un lien pour r\u00e9initialiser votre mot de passe.",
    "Unable to save customer": "Impossible d'enregistrer le client",
    "Successfully updated customer": "Client mis \u00e0 jour avec succ\u00e8s",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "La connexion au compte est incorrecte ou votre compte a \u00e9t\u00e9 temporairement d\u00e9sactiv\u00e9. Attendez et r\u00e9essayez plus tard.",
    "Successfully saved address": "Adresse enregistr\u00e9e avec succ\u00e8s",
    "Address not found": "Adresse introuvable",
    "Unable to update newsletter subscription": "Impossible de mettre \u00e0 jour l'abonnement au bulletin d'information",
    "Successfully updated newsletter subscription": "Abonnement au bulletin d'information mis \u00e0 jour avec succ\u00e8s",
    "Something went wrong while recovering your password": "Une erreur s'est produite lors de la r\u00e9cup\u00e9ration de votre mot de passe",
    "Your password has been updated": "Votre mot de passe a \u00e9t\u00e9 mis \u00e0 jour",
    "Something went wrong, please try again": "Une erreur s'est produite, veuillez r\u00e9essayer",
    "Unable to remove address": "Impossible de supprimer l'adresse",
    "Successfully removed address": "Adresse supprim\u00e9e avec succ\u00e8s",
    "Order not found": "Commande introuvable",
    "Review not found": "Avis introuvable",
    "successfully logged out": "D\u00e9connexion r\u00e9ussie",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "La connexion au compte est incorrecte ou votre compte est temporairement d\u00e9sactiv\u00e9. Veuillez attendre et r\u00e9essayer plus tard.",
    "If an account with the given email address exists you will receive a password reset email.": "Si un compte existe avec l'adresse e-mail donn\u00e9e, vous recevrez un e-mail de r\u00e9initialisation du mot de passe.",
    "Reset password": "R\u00e9initialiser le mot de passe",
    "validation.required": "Champ obligatoire",
    "validation.email": "Veuillez saisir une adresse e-mail valide",
    "Your last order": "Votre derni\u00e8re commande",
    "View all orders": "Voir toutes les commandes",
    "Details & Preferences": "D\u00e9tails et pr\u00e9f\u00e9rences",
    "Default addresses": "Adresses par d\u00e9faut",
    "View all addresses": "Voir toutes les adresses",
    "Pending": "En attente",
    "Processing": "En cours de traitement",
    "Pending Payment": "Paiement en attente",
    "Complete": "Termin\u00e9e",
    "Cancelled": "Annul\u00e9e",
    "Closed": "Ferm\u00e9e",
    "On Hold": "En attente",
    "Payment Review": "Examen du paiement",
    "Fraud": "Fraude",
    "Ordered on": "Command\u00e9e le",
    "Download invoice": "T\u00e9l\u00e9charger la facture",
    "Order details": "D\u00e9tails de la commande",
    "Order number": "Num\u00e9ro de commande",
    "subtotal": "Sous-total",
    "total_shipping": "Livraison",
    "grand_total": "Total",
    "Address and payment information": "Informations sur l'adresse et le paiement",
    "Back to my orders": "Retour \u00e0 mes commandes",
    "Support level": "Niveaux de soutien",
    "No products were found in this category.": "Aucun produit n'a \u00e9t\u00e9 trouv\u00e9 dans cette cat\u00e9gorie.",
    "Choose your {option}": "Choisissez votre {option}",
    "This product is currently sold out": "Ce produit est actuellement \u00e9puis\u00e9",
    "Choose an option": "Choisissez une option",
    "Product has been added to your shopping cart": "Le produit a \u00e9t\u00e9 ajout\u00e9 \u00e0 votre panier d'achat",
    "Saving configuration...": "Enregistrement de la configuration...",
    "Configuration code ready!": "Code de configuration pr\u00eat !",
    "The configuration could not be saved, please try again later.": "La configuration n'a pas pu \u00eatre enregistr\u00e9e, veuillez r\u00e9essayer ult\u00e9rieurement.",
    "Your unique code!": "Votre code unique !",
    "Copy code": "Copier le code",
    "E-mail code": "Code e-mail",
    "Code copied!": "Code copi\u00e9 !",
    "Code sent!": "Code envoy\u00e9 !",
    "Something went wrong with sending the code, please try again.": "Une erreur s'est produite lors de l'envoi du code, veuillez r\u00e9essayer.",
    "This product is available in the following options": "Ce produit est disponible dans les options suivantes",
    "Show address": "Afficher l'adresse",
    "We couldn't retrieve your location, please try again or use the search function.": "Nous n'avons pas pu r\u00e9cup\u00e9rer votre position, veuillez r\u00e9essayer ou utiliser la fonction de recherche.",
    "Find your Ergosleep\u00ae store": "Trouvez votre magasin Ergosleep\u00ae",
    "Send": "Envoyer",
    "Configure this bed": "Cr\u00e9ez le lit de vos r\u00eaves",
    "Your unique bed": "Votre lit unique",
    "Newest": "Plus r\u00e9cent",
    "View configuration": "Voir la configuration",
    "Productverrijking": "Sp\u00e9cifications du produit",
    "Read more": "Lire la suite",
    "Find a store near you.": "Trouvez un magasin pr\u00e8s de chez vous.",
    "Filter by country": "Filtrer par pays",
    "All countries": "Tous les pays",
    "Filter": "Filtrer",
    "Opening hours": "Heures d'ouverture",
    "Regular opening hours": "Heures d'ouverture r\u00e9guli\u00e8res",
    "Monday": "Lundi",
    "Tuesday": "Mardi",
    "Wednesday": "Mercredi",
    "Thursday": "Jeudi",
    "Friday": "Vendredi",
    "Saturday": "Samedi",
    "Sunday": "Dimanche",
    "Open by appointment": "Ouvert sur rendez-vous",
    "Address details": "D\u00e9tails de l'adresse",
    "Special holidays": "Jours f\u00e9ri\u00e9s sp\u00e9ciaux",
    "Measure your SLEEP-DNA\u00ae": "Mesurez votre SLEEP-DNA\u00ae",
    "Direction information": "Informations sur la direction",
    "Accessibility": "Accessibilit\u00e9",
    "Make an appointment": "Prendre un rendez-vous",
    "Continue in my current language.": "Continuer dans ma langue actuelle."
}